import {
    enableBodyScroll,
    disableBodyScroll
} from '@/helpers/bodyScrollLockWrapper';

const classes = {
    hide: 'hide',
    active: 'active',
};

const attributes = {
    modal: 'data-modal',
    modalToggle: 'data-modal-toggle',
    modalClose: 'data-modal-close',
    modalPlayVideo: 'data-modal-play-video',
    modalOverlay: 'data-modal-overlay',
    closeModal: 'data-close-modal',
    init: 'data-modal-init',
};

function iOS() {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

function openModal(id) {
    const modal = document.querySelector(`[${attributes.modal}="${id}"]`);
    if (!modal) {
        return;
    }

    const modalVideo = modal?.querySelector('video');
    const modalVideoHeadline = modal?.querySelector('[data-modal-video-headline]');

    if (!!modalVideoHeadline) {
        modalVideoHeadline?.classList?.remove(classes.hide);
    }

    function standardOpen() {
        modal?.classList?.remove(classes.hide);
    }

    if (modalVideo && window.innerWidth <= 500) {
        const iOSDevice = iOS();

        if (iOSDevice === true) {
            modalVideo?.play();
        } else {
            standardOpen();
        }
    } else {
        standardOpen();
    }

    const modal_content_container = modal?.querySelector('.modal__content');
    if (!!modal_content_container) {
        disableBodyScroll(modal_content_container);
    }

    const e = new Event('modalOpened');

    document.dispatchEvent(e);
}

export function initModals() {
    const modals = Array.prototype.slice.call(document.querySelectorAll('[data-modal]'));
    const modalToggles = Array.prototype.slice.call(document.querySelectorAll('[data-modal-toggle]'));
    const modalCloseButtons = Array.prototype.slice.call(document.querySelectorAll('[data-modal-close]'));
    const modalPlayVideoButtons = Array.prototype.slice.call(document.querySelectorAll('[data-modal-play-video]'));
    const modalOverlays = Array.prototype.slice.call(document.querySelectorAll('[data-modal-overlay]'));

    if (!modals) {
        return;
    }

    if (!modals?.length) {
        return;
    }

    function closeModal(closeEvent) {
        const id = closeEvent?.currentTarget?.getAttribute('data-close-modal');

        modals?.filter((modal) => {
            if (!id) {
                return false;
            }

            const modalId = modal?.getAttribute('data-modal');
            if (!modalId) {
                return false;
            }

            return modalId === id;
        })?.forEach((modal) => {
            modal?.classList?.add(classes.hide);
            modal?.classList?.remove(classes?.active);

            const modalVideo = modal?.querySelector('video');
            if (!modalVideo) {
                return;
            }

            modalVideo?.pause();
        });

        modalOverlays?.filter((overlay) => {
            if (!id) {
                return false;
            }

            const modalId = overlay?.getAttribute('data-close-modal');
            if (!modalId) {
                return false;
            }

            return modalId === id;
        }).forEach((modalOverlay) => {
            modalOverlay?.classList?.add(classes.hide);
        });

        const modal_content_container = document.querySelector(`[data-modal="${id}"] .modal__content`);
        if (!!modal_content_container) {
            enableBodyScroll(modal_content_container);
        }

        document.dispatchEvent(new CustomEvent('modalClosed', {
            detail: id
        }));
    }

    document.addEventListener('click', (event) => {
        let { target } = event;
        if (!target) {
            return;
        }

        if (target === document) {
            return;
        }

        if (!target?.hasAttribute('data-modal-toggle')) {
            target = target?.closest('[data-modal-toggle]');
        }

        if (!target) {
            return;
        }

        if (!target?.hasAttribute('data-modal-toggle')) {
            return;
        }

        openModal(target?.getAttribute('data-modal-toggle'), target);
    });

    modalToggles?.forEach((modalToggle) => {
        if (!!modalToggle?.hasAttribute('data-modal-init')) {
            return;
        }

        modalToggle?.addEventListener('click', () => {
            openModal(modalToggle?.getAttribute('data-modal-toggle'));
        });

        modalToggle?.setAttribute('data-modal-init', true);
    });

    modalCloseButtons?.forEach((modalCloseButton) => {
        if (!!modalCloseButton?.hasAttribute('data-modal-init')) {
            return;
        }

        modalCloseButton?.addEventListener('click', (event) => {
            closeModal(event);
        });

        modalCloseButton?.setAttribute('data-modal-init', true);
    });

    document.addEventListener('keydown', (event) => {
        if (event.key !== 'Escape') {
            return;
        }

        closeModal(event);
    });

    modalPlayVideoButtons?.forEach((modalPlayVideoButton) => {
        if (!!modalPlayVideoButton?.hasAttribute('data-modal-init')) {
            return;
        }

        modalPlayVideoButton?.addEventListener('click', () => {
            const parentModal = modalPlayVideoButton?.closest('[data-modal]');

            parentModal?.querySelector('[data-modal-video-headline]')?.classList?.add(classes?.hide);
            parentModal?.querySelector('video')?.play();
        });

        modalPlayVideoButton?.setAttribute('data-modal-init', true);
    });

    modalOverlays?.forEach((modalOverlay) => {
        modalOverlay?.addEventListener('click', (event) => {
            closeModal(event);
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initModals();
});

document.addEventListener('shopify:section:load', () => {
    initModals();
});
