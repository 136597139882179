import { initModals } from '@/scripts/snippets/modal';
import axios from 'axios';

import * as sections from '@shopify/theme-sections';
import ProductForm from '@savchukoleksii/shopify-product-form';
import { moneyWithCurrencyProvided, moneyWithoutTrailingZeros } from '@/filters';
import Choices from "choices.js";

import {
    disableBodyScroll,
    enableBodyScroll
} from '@/helpers/bodyScrollLockWrapper';

import { debounce } from "throttle-debounce";
import { getCart } from "@/api/cart";
import getProduct from "@/core/helpers/getProduct";
import serialize from "form-serialize";

import { EVENT_MINICART_PRODUCT_ADD, EVENT_MINICART_UPDATED } from "@/store/events";

const ACTIVE_CURRENCY = window?.Shopify?.currency?.active ?? 'USD';
const SHOW_CURRENCY_NAME = false;
const REMOVE_TRAILING_ZEROS = true;

function _serializeOptionValues(inputs, transform) {
    return inputs.reduce((options, input) => {
        if (
            input.checked || // If input is a checked (means type radio or checkbox)
            (input.type !== "radio" && input.type !== "checkbox") // Or if its any other type of input
        ) {
            options.push(transform({ name: input.name, value: input.value }));
        }

        return options;
    }, []);
}

// Override for correct options execution
ProductForm.prototype.options = function () {
    return _serializeOptionValues(this.optionInputs, (item) => {
        const regex = /(product-[\d]+-)?(options\[)?(?<name>.*?)(?:\])/;

        item.name = regex?.exec(item?.name)?.groups?.name; // Use just the value between 'options[' and ']'

        return item;
    });
};

export const SECTION_NAME = 'quickview-new';

const selectors = {
    quickViewModal: `[data-quickview-modal]`,
    productForm: '[data-product-form]',
    productSelect: '[data-product-select]',
    productJson: '[data-product-json]',
    optionLabels: '[data-quickview-selected-value]',
    options: '[data-quickview-option-value]',
    variants: '[data-quickview-variant]', 
    optionInput: '[data-single-option-selector]',
    submitButton: '[data-add-variants-to-cart]',
    close: '[data-quickview-close]',
    opened: 'account-dashboard-modal--opened',
    titleOption: '[data-quickview-title-option]',
    purchaseType: 'input[name*="_purchase_option"]:checked',
    subscriptionDetails: '[data-quickview-subscription-details]',
    sellingPlanInput: '[data-selling-plans-selector]',
    total: '[data-quickview-total]',
    subtotal: '[data-quickview-subtotal]',
    boxPrice: '[data-quick-view-box-price]',
    boxPriceCompareAt: '[data-quick-view-box-price-compare-at]',
    otpPrice: '[data-quick-view-otp-price]',
    otpPriceCompareAt: '[data-quick-view-otp-price-compare-at]',
    qtyInput: '[data-quickview-quantity-input]',
    variantMemberPrice: '[data-variant-member-price]'
};

class QuickView {
    // data
    container = null;

    selectedOptions = null;

    memberDiscount = 0;

    selectedVariants = [];

    button = null;

    _lashSettings = null;

    totalLashes = 0;

    // elements
    quickViewModal = null;

    productForm = null;

    optionElements = [];

    optionLabels = [];

    variantsElements = [];

    optionInputs = [];

    sellingPlanInput = null;

    wrappedSellingPlanInput = null;

    _cart = null;

    _frequency = null;

    _product = null;

    _selling_plan_id = null;

    _total_lashes = null;

    _purchaseType = null;

    _settings = null;

    _productForm = null;

    _currentVariant = null;

    _quantity = 0;

    _default_quantity = 0;

    _member_discount = 0;

    set lashSettings(lashSettings) {
        this._lashSettings = lashSettings;
    }

    // setters
    set frequency(frequency) {
        this._frequency = frequency;
    }

    set cart(cart) {
        this._cart = cart;
    }

    set product(product) {
        this._product = product;
    }

    set currentVariant(currentVariant) {
        this._currentVariant = currentVariant;
    }

    set purchaseType(purchaseType) {
        this._purchaseType = purchaseType;
    }

    set selling_plan_id(selling_plan_id) {
        this._selling_plan_id = +selling_plan_id;
    }

    set quantity(quantity) {
        this._quantity = quantity;
    }

    set member_discount(member_discount) {
        this._member_discount = parseFloat(member_discount);
    }

    // getters
    get currentVariant() {
        return this._currentVariant;
    }

    get frequency() {
        return this._frequency;
    }

    get quantity() {
        return this._quantity;
    }

    get default_quantity() {
        return this._default_quantity;
    }

    get product() {
        return this._product;
    }

    get cart() {
        const cart = this._cart;
        if (!cart) {
            return null;
        }

        return cart;
    }

    get selling_plan_groups() {
        const product = this.product;
        if (!product) {
            return [];
        }

        const selling_plan_groups = product?.selling_plan_groups;
        if (!selling_plan_groups) {
            return [];
        }

        if (!selling_plan_groups?.length) {
            return [];
        }

        return selling_plan_groups;
    }

    get cart_subscription_items() {
        const cart = this.cart;
        if (!cart) {
            return 0;
        }

        const items = cart?.items;
        if (!items) {
            return 0;
        }

        if (!items?.length) {
            return 0;
        }

        return items?.filter((item) => {
            return !!item?.selling_plan_allocation;
        })?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return true;
            }

            const keys = Object?.keys(properties);
            if (!keys) {
                return true;
            }

            if (!keys?.length) {
                return true;
            }

            if (!!`%%%${keys.join('%%%') || ''}`?.includes('%%%_rc')) {
                return false;
            }

            if (!keys?.includes('_inveterate_subscription')) {
                return true;
            }

            return !properties['_inveterate_subscription'];
        });
    }

    get membership_already_in_cart() {
        const cart = this.cart;
        if (!cart) {
            return 0;
        }

        const items = cart?.items;
        if (!items) {
            return 0;
        }

        if (!items?.length) {
            return 0;
        }

        return !!items?.filter((item) => {
            return !!item?.selling_plan_allocation;
        })?.find((item) => {
            const properties = item?.properties;
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            if (!keys?.includes('_inveterate_subscription')) {
                return false;
            }

            return !!properties['_inveterate_subscription'];
        });
    }

    get lash_subscription_items() {
        const cart_subscription_items = this.cart_subscription_items;

        console.log({
            cart_subscription_items,
        });

        if (!cart_subscription_items) {
            return 0;
        }

        if (!cart_subscription_items?.length) {
            return 0;
        }

        return cart_subscription_items?.filter((item) => {
            const properties = item?.properties || {};
            if (!properties) {
                return false;
            }

            const keys = Object.keys(properties);
            if (!keys) {
                return false;
            }

            if (!keys?.length) {
                return false;
            }

            if (!keys?.includes('_is_lash')) {
                return false;
            }

            const is_lash = properties['_is_lash'];
            if (!is_lash) {
                return false;
            }

            return is_lash === "1";
        })?.filter((item) => {
            const properties = item?.properties;
            if (!properties) {
                return true;
            }

            const keys = Object?.keys(properties) || [];
            if (!keys) {
                return true;
            }

            if (!keys?.length) {
                return true;
            }

            return !keys?.includes('_subscription_upsell');
        }) || [];
    }

    get cart_total_subscriptions_quantity() {
        const cart_subscription_items = this.cart_subscription_items;
        if (!cart_subscription_items) {
            return 0;
        }

        if (!cart_subscription_items?.length) {
            return 0;
        }

        return cart_subscription_items?.reduce((total, item) => {
            return total + item.quantity;
        }, 0) || 0;
    }

    get cart_total_lashes_quantity() {
        const lash_subscription_items = this.lash_subscription_items;
        if (!lash_subscription_items) {
            return 0;
        }

        if (!lash_subscription_items?.length) {
            return 0;
        }

        return lash_subscription_items?.reduce((total, item) => {
            return total + (item?.quantity || 0);
        }, 0) || 0;
    }

    get total_lashes_quantity() {
        return this.cart_total_lashes_quantity + this.quantity;
    }

    get has_subscription() {
        const selling_plan_groups = this.selling_plan_groups;
        if (!selling_plan_groups) {
            return false;
        }

        return !!selling_plan_groups?.length;
    }

    get options_with_values() {
        const product = this.product;
        if (!product) {
            return [];
        }

        const options_with_values = product?.options_with_values;
        if (!options_with_values) {
            return [];
        }

        if (!options_with_values?.length) {
            return [];
        }

        return options_with_values;
    }

    get named_options() {
        const options_with_values = this.options_with_values;
        if (!options_with_values) {
            return {};
        }

        if (!options_with_values?.length) {
            return {};
        }

        return options_with_values?.reduce((named_options, option) => {
            const name = option?.name?.toLowerCase();
            if (!name) {
                return named_options;
            }

            const position = option?.position;
            if (!position) {
                return named_options;
            }

            return {
                ...named_options,
                [name]: `option${position}`,
            };
        }, {});
    }

    get subscription_product_option_mappings() {
        const named_options = this.named_options;
        if (!named_options) {
            return {};
        }

        const named_option_keys = Object.keys(named_options);
        if (!named_option_keys) {
            return {};
        }

        if (!named_option_keys?.length) {
            return {};
        }

        const product = this.product;
        if (!product) {
            return {};
        }

        const options_with_values = product?.options_with_values;
        if (!options_with_values) {
            return {};
        }

        if (!options_with_values?.length) {
            return {};
        }

        return options_with_values?.reduce((subscription_product_option_mappings, option) => {
            const name = option?.name?.toLowerCase();
            if (!name) {
                return subscription_product_option_mappings;
            }

            const position = option?.position;
            if (!name) {
                return subscription_product_option_mappings;
            }

            if (!named_option_keys?.includes(name)) {
                return subscription_product_option_mappings;
            }

            const key = named_options[name];
            if (!key) {
                return subscription_product_option_mappings;
            }

            return {
                ...subscription_product_option_mappings,
                [key]: `option${position}`,
            };
        }, {}) || {};
    }

    get purchaseType() {
        const purchaseType = this._purchaseType;
        if (!purchaseType) {
            if (!!this.has_subscription) {
                return "subscription";
            }

            return "onetime";
        }

        return this._purchaseType;
    }

    get isLashProduct() {
        const product = this.product;
        if (!product) {
            return false;
        }

        const tags = product?.tags;
        if (!tags) {
            return false;
        }

        if (!tags?.length) {
            return false;
        }

        const downcased_tags = tags?.map((tag) => {
            return tag?.toLowerCase();
        });

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        return !!downcased_tags?.includes('lash');
    }

    get isRetailProduct() {
        const product = this.product;
        if (!product) {
            return false;
        }

        const tags = product?.tags;
        if (!tags) {
            return false;
        }

        if (!tags?.length) {
            return false;
        }

        const downcased_tags = tags?.map((tag) => {
            return tag?.toLowerCase();
        });

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        return !!downcased_tags?.includes('retail');
    }

    get isMembershipProduct() {
        const product = this.product;
        if (!product) {
            return false;
        }

        const tags = product?.tags;
        if (!tags) {
            return false;
        }

        if (!tags?.length) {
            return false;
        }

        const downcased_tags = tags?.map((tag) => {
            return tag?.toLowerCase();
        });

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        return !!downcased_tags?.includes('inveterate-product');
    }

    get isActiveSubscriber() {
        const customer = window.globals?.customer;
        if (!customer) {
            return false;
        }

        const tags = customer?.tags;
        if (!tags) {
            return false;
        }

        if (!tags?.length) {
            return false;
        }

        const downcased_tags = tags?.map((tag) => {
            return tag?.toLowerCase();
        });

        if (!downcased_tags) {
            return false;
        }

        if (!downcased_tags?.length) {
            return false;
        }

        if (!!downcased_tags?.includes('inveterate-cancelled')) {
            return false;
        }

        return !![
            'Inveterate Tier: Membership X',
            'Inveterate Tier: X Pass Monthly',
        ]?.map((member_tag) => {
            return !!downcased_tags?.includes(member_tag?.toLowerCase());
        })?.includes(true);
    }

    get lashSettings() {
        return this._lashSettings;
    }

    get discount_levels() {
        const lashSettings = this.lashSettings;
        if (!lashSettings) {
            return [];
        }

        const frequencies = lashSettings?.frequencies;
        if (!frequencies) {
            return [];
        }

        const frequency = this.frequency;
        if (!frequency) {
            return [];
        }

        const keys = Object.keys(frequencies);
        if (!keys) {
            return [];
        }

        if (!keys?.length) {
            return [];
        }

        const downcased_frequency = frequency?.toLowerCase();
        if (!downcased_frequency) {
            return [];
        }

        if (!keys?.includes(downcased_frequency)) {
            return [];
        }

        const values = frequencies[downcased_frequency];
        if (!values) {
            return [];
        }

        if (!values?.length) {
            return [];
        }

        const discount_levels = values?.map((value, index) => {
            return {
                ...value,
                index,
            };
        });

        if (!discount_levels) {
            return [];
        }

        if (!discount_levels?.length) {
            return [];
        }

        const member_pricing_available = this.member_pricing_available;
        if (!member_pricing_available) {
            return discount_levels;
        }

        if (downcased_frequency !== "30 day") {
            return discount_levels;
        }

        return discount_levels?.map((discount_level) => {
            return {
                ...discount_level,
                discount: 1 - discount_level?.discount / 100,
            };
        })?.map((discount_level) => {
            const discount = discount_level?.discount;
            if (discount === 1) {
                return {
                    ...discount_level,
                    discount: 0,
                };
            }

            return {
                ...discount_level,
                discount: (1 - discount_level.discount * this.current_member_discount_multiplier) * 100,
            };
        })?.map((discount_level) => {
            return {
                ...discount_level,
                discount: discount_level?.discount?.toFixed(2),
            };
        })?.map((discount_level) => {
            return {
                ...discount_level,
                discount: parseFloat(discount_level.discount),
            };
        });
    }

    get discounts_by_quantities() {
        const discount_levels = this.discount_levels;
        if (!discount_levels) {
            return [];
        }

        if (!discount_levels?.length) {
            return [];
        }

        let discounts_quantity_list = [];
        for (const current_discount_level of discount_levels) {
            const min_value = current_discount_level?.min_value || 0;
            const max_value = current_discount_level?.max_value || min_value;
            const discount  = current_discount_level?.discount || 0.0;

            for (let quantity = min_value; quantity <= max_value; quantity++) {
                discounts_quantity_list = [
                    ...discounts_quantity_list,
                    discount,
                ];
            }
        }

        return discounts_quantity_list;
    }

    get current_discount_levels() {
        const discount_levels = this.discount_levels;
        if (!discount_levels) {
            return [];
        }

        if (!discount_levels?.length) {
            return [];
        }

        return discount_levels;
    }

    get current_discounts_by_quantities() {
        const discounts_by_quantities = this.discounts_by_quantities;
        if (!discounts_by_quantities) {
            return [];
        }

        if (!discounts_by_quantities?.length) {
            return [];
        }

        return discounts_by_quantities;
    }

    get current_unique_discounts() {
        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return [];
        }

        const discounts = [
            ...new Set(current_discount_levels?.map((discount_level) => {
                return discount_level?.discount;
            }))
        ].filter((discount) => {
            return !!discount;
        });

        if (!discounts) {
            return [];
        }

        if (!discounts?.length) {
            return [];
        }

        return discounts;
    }

    get current_member_unique_discounts() {
        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return [];
        }

        return [
            ...new Set(current_discount_levels?.map((discount_level) => {
                return discount_level?.discount;
            }))
        ]?.filter((discount) => {
            return !!discount;
        }) || [];
    }

    get current_discount_level() {
        const total_lashes_quantity = this.cart_total_lashes_quantity || 0;

        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return null;
        }

        if (!current_discount_levels?.length) {
            return null;
        }

        const current_discount_level = current_discount_levels?.find((discount_level) => {
            return ![
                discount_level?.min_value <= total_lashes_quantity,
                discount_level?.max_value === null ? true : total_lashes_quantity <= discount_level?.max_value ,
            ]?.includes(false);
        });

        if (!current_discount_level) {
            return null;
        }

        return current_discount_level;
    }

    get current_discount_level_index() {
        const current_discount_level = this.current_discount_level;
        if (!current_discount_level) {
            return 0;
        }

        const index = current_discount_level?.index;
        if (!index) {
            return 0;
        }

        return index;
    }

    get next_discount_level() {
        const total_lashes_quantity = this.total_lashes_quantity || 0;

        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return null;
        }

        if (!current_discount_levels?.length) {
            return null;
        }

        const next_discount_level = current_discount_levels?.find((discount_level) => {
            return ![
                discount_level?.min_value <= total_lashes_quantity,
                discount_level?.max_value === null ? true : total_lashes_quantity <= discount_level?.max_value ,
            ]?.includes(false);
        });

        if (!next_discount_level) {
            return null;
        }

        return next_discount_level;
    }

    get next_discount_level_index() {
        const next_discount_level = this.next_discount_level;
        if (!next_discount_level) {
            return 0;
        }

        const index = next_discount_level?.index;
        if (!index) {
            return 0;
        }

        return index;
    }

    get current_discount_message() {
        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return "";
        }

        if (!current_discount_levels?.length) {
            return "";
        }

        const frequency = this.frequency;
        if (!frequency) {
            return "";
        }

        const current_discount_level = this.current_discount_level;
        if (!current_discount_level) {
            return "";
        }

        const message = this.default_quantity === 0 ? (
            current_discount_level?.messages?.next
        ) : (
            this.cart_total_lashes_quantity > 0 ? (
                current_discount_level?.messages?.next
            ) : (
                current_discount_level?.messages?.current
            )
        );
        if (!message) {
            return "";
        }

        const next_discount_level = current_discount_levels[this.current_discount_level_index + 1] || current_discount_levels[current_discount_levels.length - 1] || null;

        return message?.replaceAll(`{{ frequency }}`, frequency)
            ?.replaceAll(`{{ discount }}`, current_discount_level?.discount)
            ?.replaceAll(`{{ current_discount }}`, current_discount_level?.discount)
            ?.replaceAll("{{ min_value }}", current_discount_level?.min_value || "")
            ?.replaceAll("{{ max_value }}", current_discount_level?.max_value || "")
            ?.replaceAll("{{ next_discount }}", next_discount_level?.discount || "")
            ?.replaceAll("{{ next_min_value }}", next_discount_level?.min_value || "")
            ?.replaceAll("{{ next_max_value }}", next_discount_level?.max_value || "");
    }

    get next_discount_message() {
        const current_discount_levels = this.current_discount_levels;
        if (!current_discount_levels) {
            return "";
        }

        if (!current_discount_levels?.length) {
            return "";
        }

        const frequency = this.frequency;

        if (!frequency) {
            return "";
        }

        const discount_level = this.next_discount_level;
        if (!discount_level) {
            return "";
        }

        const message = this.default_quantity === 0 ? (
            discount_level?.messages?.next
        ) : (
            this.cart_total_lashes_quantity > 0 ? (
                discount_level?.messages?.next
            ) : (
                discount_level?.messages?.current
            )
        );

        if (!message) {
            return "";
        }

        const next_discount_level = current_discount_levels[this.next_discount_level_index + 1] || current_discount_levels[current_discount_levels.length - 1] || null;

        return message?.replaceAll(`{{ frequency }}`, frequency)
            ?.replaceAll(`{{ discount }}`, discount_level?.discount)
            ?.replaceAll(`{{ current_discount }}`, discount_level?.discount)
            ?.replaceAll("{{ min_value }}", discount_level?.min_value || "")
            ?.replaceAll("{{ max_value }}", discount_level?.max_value || "")
            ?.replaceAll("{{ next_discount }}", next_discount_level?.discount || "")
            ?.replaceAll("{{ next_min_value }}", next_discount_level?.min_value || "")
            ?.replaceAll("{{ next_max_value }}", next_discount_level?.max_value || "");
    }

    get button_text() {
        if (!!this.isMembershipProduct) {
            if (!!this.membership_already_in_cart) {
                return 'Already in a bag';
            }

            if (!!this.isActiveSubscriber) {
                return 'You are active member';
            }

            return window.theme?.strings?.addToBag || 'Add to Bag';
        }

        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return window.theme?.strings?.unavailable || 'Unavailable';
        }

        const available = currentVariant?.available;
        if (!available) {
            return window.theme?.strings?.soldOut || 'Sold Out';
        }

        const purchaseType = this.purchaseType;
        if (!purchaseType) {
            return window.theme?.strings?.addToBag || 'Add to Bag';
        }

        if (purchaseType?.toLowerCase() !== 'subscription') {
            return window.theme?.strings?.addToBag || 'Add to Bag';
        }

        const cart_total_subscriptions_quantity = this.cart_total_subscriptions_quantity;
        if (cart_total_subscriptions_quantity > 0) {
            return window.theme?.strings?.addToBox || 'Add to Box';
        }

        return window.theme?.strings?.startMyBox || 'Start My Box';
    }

    get current_volume_discount() {
        const current_discounts_by_quantities = this.current_discounts_by_quantities;
        if (!current_discounts_by_quantities) {
            return 0;
        }

        if (!current_discounts_by_quantities?.length) {
            return 0;
        }

        const total_lashes_quantity = this.cart_total_lashes_quantity || 0;

        return total_lashes_quantity >= current_discounts_by_quantities?.length ? current_discounts_by_quantities[current_discounts_by_quantities.length - 1] : current_discounts_by_quantities[total_lashes_quantity];
    }

    get next_volume_discount() {
        const current_discounts_by_quantities = this.current_discounts_by_quantities;
        if (!current_discounts_by_quantities) {
            return 0;
        }

        if (!current_discounts_by_quantities?.length) {
            return 0;
        }

        const total_lashes_quantity = this.total_lashes_quantity || 1;

        return total_lashes_quantity >= current_discounts_by_quantities?.length ? current_discounts_by_quantities[current_discounts_by_quantities.length - 1] : current_discounts_by_quantities[total_lashes_quantity];
    }

    get max_volume_discount() {
        const current_discounts_by_quantities = this.current_discounts_by_quantities;
        if (!current_discounts_by_quantities) {
            return 0;
        }

        if (!current_discounts_by_quantities?.length) {
            return 0;
        }

        return current_discounts_by_quantities[current_discounts_by_quantities.length - 1];
    }

    get selling_plan_id() {
        return this._selling_plan_id;
    }

    get current_variant_selling_plan_allocations() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return [];
        }

        const selling_plan_allocations = currentVariant?.selling_plan_allocations;
        if (!selling_plan_allocations) {
            return [];
        }

        if (!selling_plan_allocations?.length) {
            return [];
        }

        return selling_plan_allocations;
    }

    get current_variant_selling_plans() {
        const current_variant_selling_plan_allocations = this.current_variant_selling_plan_allocations;
        if (!current_variant_selling_plan_allocations) {
            return [];
        }

        if (!current_variant_selling_plan_allocations?.length) {
            return [];
        }

        const current_variant_selling_plans = current_variant_selling_plan_allocations?.map((selling_plan_allocation) => {
            return selling_plan_allocation?.selling_plan;
        }).filter((selling_plan) => {
            return !!selling_plan;
        });

        if (!current_variant_selling_plans) {
            return [];
        }

        if (!current_variant_selling_plans?.length) {
            return [];
        }

        return current_variant_selling_plans;
    }

    get current_variant_selected_selling_plan() {
        const selling_plan_id = this.selling_plan_id;
        if (!selling_plan_id) {
            return null;
        }

        const current_variant_selling_plans = this.current_variant_selling_plans;
        if (!current_variant_selling_plans) {
            return null;
        }

        if (!current_variant_selling_plans?.length) {
            return null;
        }

        const selected_selling_plan = current_variant_selling_plans?.find((selling_plan) => {
            return selling_plan?.id === selling_plan_id;
        });

        if (!selected_selling_plan) {
            return null;
        }

        return selected_selling_plan;
    }

    get current_variant_selected_selling_plan_price_adjustments() {
        const current_variant_selected_selling_plan = this.current_variant_selected_selling_plan;
        if (!current_variant_selected_selling_plan) {
            return [];
        }

        const price_adjustments = current_variant_selected_selling_plan?.price_adjustments;
        if (!price_adjustments) {
            return [];
        }

        if (!price_adjustments?.length) {
            return [];
        }

        return price_adjustments;
    }

    get price_adjustments_value() {
        const current_variant_selected_selling_plan_price_adjustments = this.current_variant_selected_selling_plan_price_adjustments;
        if (!current_variant_selected_selling_plan_price_adjustments) {
            return 0;
        }

        if (!current_variant_selected_selling_plan_price_adjustments?.length) {
            return 0;
        }

        const current_variant_first_selected_selling_plan_price_adjustment = current_variant_selected_selling_plan_price_adjustments[0];
        if (!current_variant_first_selected_selling_plan_price_adjustment) {
            return 0;
        }

        const discount = current_variant_first_selected_selling_plan_price_adjustment?.value || 0.0;

        const member_pricing_available = this.member_pricing_available;
        if (!member_pricing_available) {
            return discount;
        }

        return parseFloat(((1 - (1 - discount / 100) * this.current_member_discount_multiplier) * 100).toFixed(2));
    }

    get current_discount() {
        const is_lash_product = this.isLashProduct;
        if (!is_lash_product) {
            return this.price_adjustments_value;
        }

        return this.current_volume_discount;
    }

    get current_discount_multiplier() {
        const current_discount = this.current_discount;
        if (!current_discount) {
            return 0.0;
        }

        return 1.0 - current_discount / 100.0;
    }

    get next_discount() {
        const is_lash_product = this.isLashProduct;
        if (!is_lash_product) {
            return this.price_adjustments_value;
        }

        return this.next_volume_discount;
    }

    get next_member_discount() {
        const next_discount = this.next_discount;
        if (!next_discount) {
            return 0;
        }

        const current_member_discount_multiplier = this.current_member_discount_multiplier;
        if (!current_member_discount_multiplier) {
            return next_discount;
        }

        return ((1 - this.next_discount_multiplier * current_member_discount_multiplier) * 100).toFixed(2);
    }

    get max_discount() {
        const is_lash_product = this.isLashProduct;

        if (!is_lash_product) {
            return this.price_adjustments_value;
        }

        return this.max_volume_discount;
    }

    get discount_multiplier() {
        const current_discount = this.current_discount;
        if (!current_discount) {
            return 1.0;
        }

        return 1.0 - current_discount / 100.0;
    }

    get next_discount_multiplier() {
        const current_discount = this.next_discount;
        if (!current_discount) {
            return 1.0;
        }

        return 1.0 - current_discount / 100.0;
    }

    get max_discount_multiplier() {
        const current_discount = this.max_discount;
        if (!current_discount) {
            return 1.0;
        }

        return 1.0 - current_discount / 100.0;
    }

    get current_price() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return null;
        }

        const price = currentVariant?.price;
        if (!price) {
            return 0;
        }

        return price;
    }

    get current_member_price() {
        const current_price = this.current_price;
        if (!current_price) {
            return 0;
        }

        const current_member_discount_multiplier = this.current_member_discount_multiplier;
        if (!current_member_discount_multiplier) {
            return 0;
        }

        return current_price * current_member_discount_multiplier;
    }

    get compare_at_price() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return null;
        }

        const price = currentVariant?.compare_at_price;
        if (!price) {
            return this.current_price;
        }

        return price;
    }

    get current_subscription_price() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return 0;
        }

        const price = currentVariant?.price;
        if (!price) {
            return 0;
        }

        const discount_multiplier = this.current_discount_multiplier;
        if (!discount_multiplier) {
            return price;
        }

        return price * discount_multiplier;
    }

    get current_member_subscription_price() {
        const current_subscription_price = this.current_subscription_price;
        if (!current_subscription_price) {
            return 0;
        }

        const current_member_discount_multiplier = this.current_member_discount_multiplier;
        if (!current_member_discount_multiplier) {
            return current_subscription_price;
        }

        return current_subscription_price * current_member_discount_multiplier;
    }

    get next_subscription_price() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return 0;
        }

        const price = currentVariant?.price;
        if (!price) {
            return 0;
        }

        const discount_multiplier = this.next_discount_multiplier;
        if (!discount_multiplier) {
            return price;
        }

        return price * discount_multiplier;
    }

    get next_member_subscription_price() {
        const next_subscription_price = this.next_subscription_price;
        if (!next_subscription_price) {
            return 0;
        }

        const member_pricing_available = this.member_pricing_available;
        if (!member_pricing_available) {
            return next_subscription_price;
        }

        const current_member_discount_multiplier = this.current_member_discount_multiplier;
        if (!current_member_discount_multiplier) {
            return next_subscription_price;
        }

        return next_subscription_price;
    }

    get min_subscription_price() {
        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return null;
        }

        const price = currentVariant?.price;
        if (!price) {
            return 0;
        }

        const discount_multiplier = this.max_discount_multiplier;
        if (!discount_multiplier) {
            return price;
        }

        return price * discount_multiplier;
    }

    get member_discount() {
        const member_discount = this._member_discount;
        if (!member_discount) {
            return 0;
        }

        return member_discount;
    }

    get current_member_discount_multiplier() {
        const member_discount = this.member_discount;
        if (!member_discount) {
            return 1.0;
        }

        return 1.0 - member_discount / 100.0;
    }

    get member_pricing_available() {
        const member_discount = this.member_discount;
        if (!member_discount) {
            return false;
        }

        const isRetail = this.isRetailProduct;
        if (!isRetail) {
            return false;
        }

        return !![
            !!this.isActiveSubscriber,
            !!this.membership_already_in_cart,
        ]?.includes(true);
    }

    get subtotal() {
        const selectedVariants = this.selectedVariants;
        if (!selectedVariants) {
            return 0;
        }

        if (!selectedVariants?.length) {
            return 0;
        }

        return selectedVariants?.reduce((acc, variant) => {
            return acc + (this.getVariantById(variant.id)?.price || 0) * (variant?.quantity || 0);
        }, 0) || 0.0;
    }

    get total() {
        const subtotal = this.subtotal;
        if (!subtotal) {
            return 0.0;
        }

        const savings_discount_multiplier = this.savings_discount_multiplier;
        if (!savings_discount_multiplier) {
            return subtotal;
        }

        return subtotal * savings_discount_multiplier;
    }

    get savings_discount() {
        const purchaseType = this.purchaseType;

        switch (purchaseType?.toLowerCase()) {
            case "subscription": {
                return this.next_discount;
            }
            default: {
                return this.member_pricing_available ? this.member_discount : 0;
            }
        }
    }

    get savings_discount_multiplier() {
        const savings_discount = this.savings_discount;
        if (!savings_discount) {
            return 1.0;
        }

        return 1.0 - savings_discount / 100.0;
    }

    get savings() {
        const subtotal = this.subtotal;
        if (!subtotal) {
            return 0;
        }

        const total = this.total;
        if (!total) {
            return subtotal;
        }

        return subtotal - total;
    }

    constructor(container) {
        if (!container) {
            return;
        }

        this.container = container;

        this.init();
    }

    async init() {
        const container = this.container;
        if (!container) {
            return;
        }

        container.addEventListener("quickview:open", this.open.bind(this));
        container.addEventListener("quickview:close", this.open.bind(this));

        this.initQuickViewModal();

        await this.initProduct();
        await this.initCart();

        this.memberDiscount = parseFloat(container?.dataset?.memberDiscount || '0.00');

        this.purchaseType = container?.querySelector(selectors?.purchaseType)?.value || 'onetime';

        this.initLashSettings();
        this.initCurrentVariant();
        this.initLabelsElements();
        this.initVariantsElements();
        this.initOptionElements();
        this.initOptionInputsElements();

        this.initProductForm();
        this.initAccordion();
        this.initVariantsSelect();
        this.initVariantsQantitySelect();
        this.initOnQtyChange();

        this.initCloseElements();

        this.member_discount = container?.dataset?.memberDiscount;

        const onCartChange = debounce(100, this.onCartChange.bind(this));

        document.addEventListener(EVENT_MINICART_UPDATED, onCartChange);

        if (!this.quickViewModal) {
            return;
        }

        this.quickViewModal.classList.remove('account-dashboard-modal--quick-view-loading');
    }

    initAccordion() {
        const accordions = document.querySelectorAll('.account-dashboard-quick-view__tab');
        if (!accordions) {
            return;
        }

        if (!accordions?.length) {
            return;
        }

        accordions.forEach((accordion) => {
            const accordionButton = accordion.querySelector('.account-dashboard-quick-view__tab-title');
            if (!accordionButton) {
                return;
            }

            accordionButton.addEventListener('click', () => {
                accordion?.classList?.toggle('opened');
            });
        });
    }

    async initProduct() {
        const product = await this.getProductJson();
        if (!product) {
            return;
        }

        this.product = product;
    }

    initOnQtyChange() {
        const container = this.container;
        if (!container) {
            return;
        }

        const inputs = container?.querySelectorAll(selectors?.qtyInput);
        if (!inputs) {
            return;
        }

        if (!inputs?.length) {
            return;
        }

        inputs.forEach((input) => {
            input.addEventListener('change', () => {
                let value = parseInt(input.value || '0');

                if (value <= 0) {
                    input.parentElement.classList.add('hidden');
                    input.parentElement.nextElementSibling.classList.remove('hidden');
                }

                if (value < 0) {
                    input.value = 0;
                    value = input.value;
                }

                const variant_id = input?.closest('[data-quickview-variant-id]')?.dataset?.quickviewVariantId;
                if (!variant_id) {
                    return;
                }

                this.changeSelectedVariantQuantity(variant_id, value);
                this.updateSubmitButton();
                this.updateQuantity();
                this.updatePrices();
            });
        });
    }

    async initCart() {
        try {
            this.cart = await getCart();

            return;
        } catch (error) {}

        this.cart = null;
    }

    initCurrentVariant() {
        const variant_id = parseInt(this.button?.dataset?.variantId || '0');
        if (!variant_id) {
            return;
        }

        const product = this.product;
        if (!product) {
            return;
        }

        const variants = product?.variants;
        if (!variants) {
            return;
        }

        if (!variants?.length) {
            return;
        }

        this.currentVariant = variants?.find((variant) => {
            return variant?.id === variant_id;
        }) || null;
    }

    initLashSettings() {
        const container = this.container;
        if (!container) {
            return;
        }

        const settings = container?.dataset?.settings;
        if (!settings) {
            return;
        }

        try {
            this.lashSettings = JSON.parse(settings);
        } catch (error) {}
    }

    initVariantsQantitySelect() {
        const quantityButtons = document.querySelectorAll('[data-quickview-variant] .account-dashboard-quantity-select__button');
        if (!quantityButtons) {
            return;
        }

        if (!quantityButtons?.length) {
            return;
        }

        quantityButtons?.forEach((button) => {
            button?.addEventListener('click', (event) => {
                event?.preventDefault();

                this.onVariantQuantityChange(event?.currentTarget || event?.target);
            });
        });
    }

    initQuickViewModal() {
        const { container } = this;
        if (!container) {
            return;
        }

        const quickViewModal = container?.querySelector(selectors?.quickViewModal);
        if (!quickViewModal) {
            return;
        }

        this.quickViewModal = quickViewModal;
    }

    getSellingPlanSelectedOption() {
        const sellingPlanInput = this.sellingPlanInput;
        if (!sellingPlanInput) {
            return null;
        }

        const options = sellingPlanInput?.options;
        if (!options) {
            return null;
        }

        if (!options?.length) {
            return null;
        }

        const option = options[sellingPlanInput.selectedIndex];
        if (!option) {
            return null;
        }

        return option;
    }

    parseCustomSelectOptionProperties(option) {
        if (!option) {
            return {};
        }

        try {
            const properties = JSON.parse(option?.dataset?.customProperties || "{}");
            if (!properties) {
                return {};
            }

            return properties;
        } catch (error) {}

        return {};
    }

    onSellingPlanChange() {
        const selected_option = this.getSellingPlanSelectedOption();
        if (!selected_option) {
            return;
        }

        const properties = this.parseCustomSelectOptionProperties(selected_option);
        if (!properties) {
            return;
        }

        const frequency = properties?.frequency;
        if (!frequency) {
            return;
        }

        this.frequency = frequency;
        this.selling_plan_id = parseInt(selected_option.value);

        this.updateFrequencyOrderIntervalPropertyInput(properties?.frequency_interval || 30);
        this.updateFrequencyUnitPropertyInput(properties?.frequency_unit || "day");
        this.updatePrices();
    }

    updateFrequencyOrderIntervalPropertyInput(frequency_order_interval) {
        const input = this.container?.querySelector(`[data-selected-frequency-order-invertal]`);
        if (!input) {
            return;
        }

        input.value = frequency_order_interval;
    }

    updateFrequencyUnitPropertyInput(frequency_unit) {
        const input = this.container?.querySelector(`[data-selected-frequency-unit]`);
        if (!input) {
            return;
        }

        input.value = frequency_unit;
    }

    initProductForm() {
        const product = this.product;
        if (!product) {
            return;
        }

        const { container } = this;
        if (!container) {
            return;
        }

        const productForm = container?.querySelector(selectors?.productForm);
        if (!productForm) {
            return;
        }

        this.productForm = productForm;

        const onFormSubmit = this.onFormSubmit.bind(this);
        const onOptionChange = this.onOptionChange.bind(this);
        const onPropertyChange = this.onPropertyChange.bind(this);
        const onSellingPlanChange = this.onSellingPlanChange.bind(this);

        this.productForm = new ProductForm(
            this.productForm, product, {
                onFormSubmit,
                onOptionChange,
                onPropertyChange,
                onSellingPlanChange,
                selectors: {
                    idInput: '[name="id"]',
                    optionInput: '[data-single-option-selector]',
                    quantityInput: '[name="quantity"]',
                    propertyInput: '[name^="properties"]',
                    sellingPlanInput: '[name^="selling_plan"]',
                },
                onQuantityChange: () => {},
            }
        );

        const sellingPlanInput = container?.querySelector(selectors.sellingPlanInput);
        if (!sellingPlanInput) {
            return;
        }

        this.wrappedSellingPlanInput = new Choices(sellingPlanInput, {
            allowHTML: true,
            duplicateItemsAllowed: false,
            paste: false,
            searchEnabled: false,
            shouldSort: false,
            callbackOnCreateTemplates(template) {
                return {
                    item: ({ classNames }, data) => {
                        let custom_properties = {};
                        try {
                            custom_properties = JSON.parse(data?.customProperties || "{}");
                        } catch (error) {}

                        return template(`<div
                        class="${classNames?.item} ${data.selected ? 'selected' : ''} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}"
                        data-item
                        data-id="${data.id}"
                        data-value="${data.value}"
                        ${data.active ? 'aria-selected="true"' : ''}
                        ${Object.keys(custom_properties).map((key) => {
                            return `data-${key}="${custom_properties[key]}"`;
                        })?.join("\n") || ""}
                    >
                        <span class="product-subscription-option__select-value">${data.label}</span>
                    </div>`);
                    },
                    choice: ({ classNames }, data) => {
                        let custom_properties = {};
                        try {
                            custom_properties = JSON.parse(data?.customProperties || "{}");
                        } catch (error) {}

                        return template(`<div
                        class="${classNames.item} ${data.selected ? 'selected' : ''} ${classNames.itemChoice} ${classNames.itemSelectable} ${data?.label?.includes('30 day') ? `recommended` : ''}"
                        data-select-text="${this.config.itemSelectText}"
                        data-choice
                        data-choice-selectable
                        data-id="${data.id}"
                        data-value="${data.value}"
                        ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}
                        ${Object.keys(custom_properties).map((key) => {
                            return `data-${key}="${custom_properties[key]}"`;
                        })?.join("\n") || ""}
                    >
                        <span class="product-subscription-option__select-value">${data.label}</span>
                        
                        <span class="product-subscription-option__select-tag">More Savings</span>
                    </div>`);
                    }
                };
            },
            callbackOnInit() {
                const passedElement = this.passedElement;
                if (!passedElement) {
                    return;
                }

                const element = passedElement?.element;
                if (!element) {
                    return;
                }

                const disabled = element?.dataset?.disabled === 'disabled';
                if (!disabled) {
                    return;
                }

                this.disable();
            }
        });

        this.sellingPlanInput = sellingPlanInput;

        const selected_option = this.getSellingPlanSelectedOption();
        if (!selected_option) {
            return;
        }

        const properties = this.parseCustomSelectOptionProperties(selected_option);
        if (!properties) {
            return;
        }

        const frequency = properties?.frequency;
        if (!frequency) {
            return;
        }

        this.frequency = frequency;
        this.selling_plan_id = parseInt(selected_option.value);
    }

    updateSubmitButton() {
        const { container } = this;
        if (!container) {
            return;
        }

        const submitButtons = container?.querySelectorAll(selectors?.submitButton);
        if (!submitButtons?.length) {
            return;
        }

        if (this.selectedVariants.length > 0) {
            submitButtons.forEach((submitButton) => {
                submitButton.removeAttribute('disabled');

                const button_text_el = submitButton.querySelector('[data-add-variants-to-cart-text]');
                if (!button_text_el) {
                    return;
                }

                let buttonLabel = window.theme?.strings?.addToBag || 'Add to Bag';

                if (this.purchaseType === 'subscription') {
                    buttonLabel = !!parseInt(this.cart_total_subscriptions_quantity || '0') ? window.theme?.strings?.addToBox || 'Add to Box' : window.theme?.strings?.startMyBox || 'Start My Box';
                }

                button_text_el.textContent = buttonLabel;
            });
        } else {
            submitButtons.forEach((submitButton) => {
                submitButton.setAttribute('disabled', true);

                const button_text_el = submitButton.querySelector('[data-add-variants-to-cart-text]');
                if (!button_text_el) {
                    return;
                }

                button_text_el.textContent = 'Choose your version';
            });
        }
    }

    handleVariantSelect(btn) {
        const { container } = this;
        if (!container) {
            return;
        }

        const action = btn.dataset.quickviewVariantAction;
        if (action === 'add') {
            const input = btn.previousElementSibling?.querySelector(selectors.qtyInput);
            if (input) {
                input.value = 1;
            }

            this.selectedVariants.push({
                quantity: 1,
                id: btn?.closest('[data-quickview-variant-id]')?.dataset?.quickviewVariantId,
            });

            btn.previousElementSibling.classList.remove('hidden');
            btn.classList.add('hidden');
        }

        this.updateSubmitButton();
        this.updateQuantity();
        this.updatePrices();
    }

    initVariantsSelect() {
        const variantSelectors = document.querySelectorAll('[data-quickview-variant-action]');
        if (!variantSelectors) {
            return;
        }

        if (!variantSelectors?.length) {
            return;
        }

        variantSelectors.forEach((variantSelector) => {
            variantSelector.addEventListener('click', (event) => {
                event.preventDefault();

                const { target } = event;
                if (!target) {
                    return;
                }

                this.handleVariantSelect(target);
            });
        });
    }

    initLabelsElements() {
        const { container } = this;
        if (!container) {
            return;
        }

        const optionElements = container.querySelectorAll(selectors?.optionLabels);

        if (!optionElements) {
            return;
        }

        if (!optionElements?.length) {
            return;
        }

        const optionLabels = Array.prototype.slice.call(optionElements);
        if (!optionLabels) {
            return;
        }

        if (!optionLabels?.length) {
            return;
        }

        this.optionLabels = optionLabels;
    }

    initVariantsElements() {
        const { container } = this;
        if (!container) {
            return;
        }

        const variantElementNodes = container.querySelectorAll(selectors?.variants);
        if (!variantElementNodes) {
            return;
        }

        if (!variantElementNodes?.length) {
            return;
        }

        const variantElements = Array.prototype.slice.call(variantElementNodes);
        if (!variantElements) {
            return;
        }

        if (!variantElements?.length) {
            return;
        }

        this.variantsElements = variantElements;
    }

    initOptionElements() {
        const { container } = this;
        if (!container) {
            return;
        }

        const optionElementNodes = container.querySelectorAll(selectors?.options);
        if (!optionElementNodes) {
            return;
        }

        if (!optionElementNodes?.length) {
            return;
        }

        const optionElements = Array.prototype.slice.call(optionElementNodes);
        if (!optionElements) {
            return;
        }

        if (!optionElements?.length) {
            return;
        }

        this.optionElements = optionElements;
    }

    initCloseElements() {
        const { container } = this;
        if (!container) {
            return;
        }

        const closeElementNodes = container.querySelectorAll(selectors?.close);
        if (!closeElementNodes) {
            return;
        }

        if (!closeElementNodes?.length) {
            return;
        }

        const closeElements = Array.prototype.slice.call(closeElementNodes);
        if (!closeElements) {
            return;
        }

        if (!closeElements?.length) {
            return;
        }

        closeElements.forEach((closeElement) => {
            closeElement.addEventListener('click', () => {
                this.close();
            });
        });
    }

    initOptionInputsElements() {
        const { container } = this;
        if (!container) {
            return;
        }

        const optionInputElementNodes = container.querySelectorAll(selectors?.optionInput);
        if (!optionInputElementNodes) {
            return;
        }

        if (!optionInputElementNodes?.length) {
            return;
        }

        const optionInputElements = Array.prototype.slice.call(optionInputElementNodes);
        if (!optionInputElements) {
            return;
        }

        if (!optionInputElements?.length) {
            return;
        }

        this.optionInputs = optionInputElements;
    }

    async getProductJson() {
        const container = this.container;
        if (!container) {
            return null;
        }

        const handle = container?.dataset?.productHandle;
        if (!handle) {
            return null;
        }

        try {
            const product = await getProduct(handle);
            if (!product) {
                return null;
            }

            return {
                ...product,
                options: product?.options_with_values || [],
            };
        } catch (error) {}

        return null;
    }

    onFormSubmit(event) {
        const container = this.container;
        if (!container) {
            return;
        }

        const productForm = event?.target;
        if (!productForm) {
            return;
        }

        const data = serialize(productForm, {
            hash: true,
            disabled: true,
            empty: false
        });

        event?.preventDefault();
        event?.stopPropagation();
        event?.stopImmediatePropagation();

        const { selectedVariants } = this;
        if (!selectedVariants) {
            return;
        }

        if (!selectedVariants?.length) {
            return;
        }

        const submitButtons = container.querySelectorAll(selectors?.submitButton);
        if (!!submitButtons?.length) {
            submitButtons.forEach((submitButton) => {
                submitButton.disabled = true;
                submitButton?.classList?.add('loading');
            });
        }

        const button = this.button;
        const selling_plan_id = this.selling_plan_id;

        document.dispatchEvent(new CustomEvent(EVENT_MINICART_PRODUCT_ADD, {
            detail: {
                items: [
                    ...(selectedVariants?.map((item) => {
                        return {
                            id: Number(item?.id),
                            quantity: Number(item?.quantity),
                            ...(this.purchaseType === 'subscription' ? {
                                selling_plan: selling_plan_id,
                            } : {}),
                            properties: {
                                ...(data?.properties || {})
                            }
                        };
                    }) || [])
                ],
                callback: () => {
                    if (!!submitButtons?.length) {
                        submitButtons?.forEach((submitButton) => {
                            submitButton?.classList?.remove('loading');

                            const button_text_el = submitButton?.querySelector('[data-add-variants-to-cart-text]');
                            if (!button_text_el) {
                                return;
                            }
                            button_text_el.textContent = "Choose your version";
                        });
                    }

                    this.close();

                    if (!button) {
                        return;
                    }

                    setTimeout(() => {
                        button?.classList?.remove('loading');
                        button?.classList?.remove('loaded', 'btn--purple');

                        button.disabled = false;
                        button?.classList?.remove("disabled");
                    }, 5000);
                },
                errorCallback: () => {
                    button.disabled = false;
                    button?.classList?.remove("disabled");
                }
            }
        }));
    }

    changeSelectedVariantQuantity(id, quantity) {
        const selectedVariants = this.selectedVariants;

        if (quantity > 0) {
            this.selectedVariants = selectedVariants?.map((variant) => {
                if (variant?.id === id) {
                    return {
                        ...variant,
                        quantity
                    };
                }

                return variant;
            }) || [];

            return;
        }

        this.selectedVariants = selectedVariants.filter((variant) => {
            return variant.id !== id;
        });
    }

    onVariantQuantityChange(target) {
        const variant_id = target?.closest('[data-quickview-variant-id]')?.dataset?.quickviewVariantId;
        if (!variant_id) {
            return;
        }

        if (!!target?.classList?.contains('account-dashboard-quantity-select__button--minus')) {
            const quantity = Number(target.nextElementSibling.value);
            if (quantity > 1) {
                target.nextElementSibling.value = quantity - 1;
                this.changeSelectedVariantQuantity(variant_id, quantity - 1);
            }

            if (quantity === 1) {
                target.parentElement.classList.add('hidden');

                target.parentElement.nextElementSibling.classList.remove('hidden');

                this.changeSelectedVariantQuantity(variant_id, 0);
            }
        } else {
            const quantity = Number(target.previousElementSibling.value);

            target.previousElementSibling.value = quantity + 1;

            this.changeSelectedVariantQuantity(variant_id, quantity + 1);
        }

        this.updateSubmitButton();
        this.updateQuantity();
        this.updatePrices();
    }

    updateQuantity() {
        this.quantity = (this.selectedVariants || []).reduce((acc, item) => {
            return acc + (item?.quantity || 0);
        }, 0) || 0;
    }

    updateSubscriptionDetails() {
        const container = this.container;
        if (!container) {
            return;
        }

        const subscriptionDetails = container?.querySelector(selectors.subscriptionDetails);
        if (!subscriptionDetails) {
            return;
        }

        if (this.purchaseType === 'subscription') {
            subscriptionDetails?.classList?.remove('hide');
            return;
        }

        subscriptionDetails?.classList?.add('hide');
    }

    updateSellingPlanInput() {
        const sellingPlanInput = this.sellingPlanInput;
        if (!sellingPlanInput) {
            return;
        }

        sellingPlanInput.name = this.purchaseType === 'subscription' ? 'selling_plan' : '';
    }

    onPropertyChange(event) {
        this.onPurchaseOptionsChange(event);
    }

    onPurchaseOptionsChange(event) {
        const dataset = event.dataset;
        if (!dataset) {
            return;
        }

        const properties = dataset?.properties;
        if (!properties) {
            return;
        }

        const keys = Object.keys(properties);
        if (!keys) {
            return;
        }

        if (!keys?.length) {
            return;
        }

        if (!keys?.includes('_purchase_option')) {
            return;
        }

        const purchase_option = properties['_purchase_option'];
        if (!purchase_option) {
            return;
        }

        const options = dataset?.options;
        if (!options) {
            return;
        }

        this.purchaseType = purchase_option;

        this.updateSellingPlanInput();
        this.updateSubscriptionDetails();
        this.updatePrices();
        this.updateSubmitButton();
    }

    onOptionChange(event) {
        const { product } = this;
        if (!product) {
            return;
        }

        const variants = product?.variants;
        if (!variants) {
            return;
        }

        if (!variants?.length) {
            return;
        }

        const currentOptions = event?.dataset?.options?.reduce((selected_options, option, index) => {
            return {
                ...selected_options,
                [`option${index + 1}`]: option?.value || null
            };
        }, {});

        const keys = Object?.keys(currentOptions) || [];

        const conditions = [
            ...(keys?.map((key, index) => {
                return [...keys.slice(0, index)];
            }) || []),
            keys
        ]?.reverse();

        const variant = conditions?.map((condition) => {
            return variants?.find((variant) => {
                return !condition?.map((key) => {
                    return variant[key] === currentOptions[key];
                })?.includes(false);
            });
        })?.find((variant) => {
            return Boolean(variant);
        });

        this.currentVariant = variant;

        let selectedOptions = {
            ...(variant?.option1 ? {
                option1: variant?.option1
            } : {}),
            ...(variant?.option2 ? {
                option2: variant?.option2
            } : {}),
            ...(variant?.option3 ? {
                option3: variant?.option3
            } : {}),
        };

        selectedOptions = Object?.keys(selectedOptions)?.reduce((options, key) => {
            const value = selectedOptions[key];
            if (!value) {
                return options;
            }

            return {
                ...options,
                [key]: value
            };
        }, {}) || {};

        if (JSON?.stringify(currentOptions) !== JSON?.stringify(selectedOptions)) {
            this.optionInputs.forEach((optionInput) => {
                const option = optionInput?.dataset?.index;
                if (!option) {
                    return;
                }

                optionInput.checked = false;
                optionInput?.setAttribute('checked', undefined);

                const selectedValue = selectedOptions[option];
                if (!selectedValue) {
                    return;
                }

                const value = optionInput?.value;
                if (!value) {
                    return;
                }

                if (value !== selectedValue) {
                    return;
                }

                optionInput.checked = true;
                optionInput?.setAttribute('checked', true);
            });
        }

        this.filterOptions(selectedOptions);
        this.filterVariants(selectedOptions);
        this.changesLabels(selectedOptions);
        this.changeOptionInfo(event, selectedOptions);
        this.updateImage(selectedOptions);
        this.updateTitle(selectedOptions);
        this.updatePrices();
    }

    updateBoxPrices() {
        const container = this.container;
        if (!container) {
            return;
        }

        if (!this.currentVariant) {
            return;
        }

        const boxPriceEl = container.querySelector(selectors.boxPrice);
        if (!boxPriceEl) {
            return;
        }

        const boxPrice = this.next_subscription_price;

        boxPriceEl.innerHTML = moneyWithoutTrailingZeros(this.next_subscription_price);

        const boxPriceCompareAtEl = container?.querySelector(selectors?.boxPriceCompareAt);
        if (!boxPriceCompareAtEl) {
            return;
        }

        const boxPriceCompareAt = this.compare_at_price || boxPrice || 0;
        if (boxPriceCompareAt <= boxPrice) {
            boxPriceCompareAtEl.classList.add('hide');
            return;
        }

        boxPriceCompareAtEl.innerHTML = moneyWithoutTrailingZeros(boxPriceCompareAt);
        boxPriceCompareAtEl?.classList?.remove('hide');
    }

    changeOptionInfo(event) {
        const current = event?.target?.parentElement?.getAttribute('data-option-info') || "";

        const infoEl = event?.target?.closest('.account-dashboard-quick-view__option')?.querySelector('.account-dashboard-quick-view__option-text');
        if (!infoEl) {
            return;
        }

        infoEl.textContent = current;
    }

    changesLabels(selectedOptions) {
        const { optionLabels } = this;
        if (!optionLabels) {
            return;
        }

        if (!optionLabels?.length) {
            return;
        }

        for (const optionLabel of optionLabels) {
            const option = optionLabel?.dataset?.index;
            if (!option) {
                continue;
            }

            const value = selectedOptions[option];
            if (!value) {
                continue;
            }

            optionLabel.innerHTML = value;
        }
    }

    getFilteredOptions(selectedOptions) {
        const keys = Object.keys(selectedOptions);
        if (!keys) {
            return {};
        }

        const { product } = this;
        if (!product) {
            return {};
        }

        const variants = product?.variants;
        if (!variants) {
            return {};
        }

        if (!variants?.length) {
            return {};
        }

        const filtered_options = keys
            .map((key, index) => {
                return [...keys].splice(0, index);
            })
            .map((options) => {
                return variants?.filter((variant) => {
                    return !options
                        .map((key) => {
                            return variant[key] === selectedOptions[key];
                        })
                        .includes(false);
                });
            })
            .map((variants, index) => {
                return variants.map((variant) => {
                    return variant[`option${index + 1}`];
                });
            })
            .map((options) => {
                return [...new Set(options)];
            })
            .reduce((
                options_with_values, options, index
            ) => {
                return {
                    ...options_with_values,
                    [`option${index + 1}`]: options
                };
            }, {});

        if (!filtered_options) {
            return {};
        }

        return filtered_options;
    }

    filterOptions(selectedOptions) {
        const { optionElements } = this;
        if (!optionElements) {
            return;
        }

        if (!optionElements?.length) {
            return;
        }

        const filtered_options = this.getFilteredOptions(selectedOptions);
        if (!filtered_options) {
            return;
        }

        for (const optionElement of optionElements) {
            optionElement?.classList.add('hidden');

            const option = optionElement?.dataset?.index;
            if (!option) {
                continue;
            }

            const option_values = filtered_options[option];
            if (!option_values) {
                continue;
            }

            if (!option_values?.length) {
                continue;
            }

            const value = optionElement?.dataset?.value;
            if (!value) {
                continue;
            }

            if (!option_values?.includes(value)) {
                continue;
            }

            optionElement?.classList?.remove('hidden');
        }
    }

    filterVariants(selectedOptions) {
        const variantElements = this.variantsElements;
        if (!variantElements) {
            return;
        }

        if (!variantElements?.length) {
            return;
        }

        const { product } = this;
        if (!product) {
            return;
        }

        const variants = product?.variants;
        if (!variants) {
            return;
        }

        if (!variants?.length) {
            return;
        }

        const optionKeys = Object.keys(selectedOptions)?.slice(0, -1);

        const filtered_variants = variants?.filter((variant) => {
            return !optionKeys?.map((key) => {
                return variant[key] === selectedOptions[key];
            })?.includes(false);
        });

        if (!filtered_variants) {
            return;
        }

        if (!filtered_variants?.length) {
            return;
        }

        const filtered_variant_ids = filtered_variants?.reduce((filtered_variant_ids, variant) => {
            const id = variant?.id;
            if (!id) {
                return filtered_variant_ids;
            }

            return [...filtered_variant_ids, `${id}`];
        }, []);

        if (!filtered_variant_ids) {
            return;
        }

        if (!filtered_variant_ids?.length) {
            return;
        }

        for (const variantElement of variantElements) {
            variantElement?.classList?.add('hidden');

            const variantElementId = variantElement?.dataset?.quickviewVariantId;
            if (!variantElementId) {
                continue;
            }

            if (!filtered_variant_ids.includes(variantElementId)) {
                continue;
            }

            variantElement?.classList?.remove('hidden');
        }
    }

    updatePrices() {
        this.updateTotal();
        this.updateSubtotal();
        this.updateBoxPrices();
        this.updateOTPPrices();
        this.updateOnetimePrices();
        this.updateSubscriptionPrices();
        this.updateSubscriptionSavings();
    }

    updateOnetimePrices() {
        const container = this.container;
        if (!container) {
            return;
        }

        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return;
        }

        const price = this.member_pricing_available ? this.current_member_price : this.current_price;

        container.querySelectorAll(`[data-sale-retail-compare-at-price]`).forEach((element) => {
            element.innerHTML = moneyWithCurrencyProvided(this.compare_at_price, ACTIVE_CURRENCY, SHOW_CURRENCY_NAME, REMOVE_TRAILING_ZEROS);

            if (this.compare_at_price > price) {
                element?.classList?.remove('hide');
            } else {
                element?.classList?.add('hide');
            }
        });

        container.querySelectorAll(`[data-sale-retail-price]`).forEach((element) => {
            element.innerHTML = moneyWithCurrencyProvided(price, ACTIVE_CURRENCY, SHOW_CURRENCY_NAME, REMOVE_TRAILING_ZEROS);
        });
    }

    updateSubscriptionPrices() {
        const container = this.container;
        if (!container) {
            return;
        }

        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return;
        }

        container.querySelectorAll(`[data-next-subscription-price]`).forEach((element) => {
            element.innerHTML = moneyWithCurrencyProvided(this.next_subscription_price, ACTIVE_CURRENCY, SHOW_CURRENCY_NAME, REMOVE_TRAILING_ZEROS);
        });
    }

    updateSubscriptionSavings() {
        const container = this.container;
        if (!container) {
            return;
        }

        const currentVariant = this.currentVariant;
        if (!currentVariant) {
            return;
        }

        const current_discount          = this.current_discount || 0;
        const next_discount             = this.next_discount || 0;
        const next_member_discount      = this.next_member_discount || 0;
        const max_discount              = this.max_discount || 0;

        container.querySelectorAll(`[data-subscription-current-savings-percent-amount]`).forEach((element) => {
            element.innerHTML = current_discount;
        });

        container.querySelectorAll(`[data-subscription-next-savings-percent-amount]`).forEach((element) => {
            element.innerHTML = next_discount;
        });

        container.querySelectorAll(`[data-subscription-next-member-savings-percent-amount]`).forEach((element) => {
            element.innerHTML = next_member_discount;
        });

        container.querySelectorAll(`[data-subscription-max-savings-percent-amount]`).forEach((element) => {
            element.innerHTML = max_discount;
        });

        container.querySelectorAll(selectors.widgetSubscriptionOption).forEach((element) => {
            if (next_discount > 0) {
                element?.classList?.add('product-subscription-option--discounted');
            } else {
                element?.classList?.remove('product-subscription-option--discounted');
            }
        });
    }

    updateOTPPrices() {
        const container = this.container;
        if (!container) {
            return;
        }

        if (!this.currentVariant) {
            return;
        }

        const otpPriceEl = container?.querySelector(selectors.otpPrice);

        if (!otpPriceEl) {
            return;
        }

        const otpPrice = this.current_price;
        otpPriceEl.innerHTML = moneyWithoutTrailingZeros(otpPrice);

        const otpPriceCompareAtEl = container?.querySelector(selectors.otpPriceCompareAt);
        if (!otpPriceCompareAtEl) {
            return;
        }

        const otpPriceCompareAt = this.compare_at_price || this.current_price || 0;
        if (otpPriceCompareAt <= otpPrice) {
            otpPriceCompareAtEl?.classList?.add('hide');
            return;
        }

        otpPriceCompareAtEl.innerHTML = moneyWithoutTrailingZeros(otpPriceCompareAt);
        otpPriceCompareAtEl?.classList?.remove('hide');
    }

    updateTotal() {
        const elements = this.container?.querySelectorAll(selectors?.total);
        if (!elements) {
            return;
        }

        if (!elements?.length) {
            return;
        }

        const total = this.total || 0.0;

        elements?.forEach((el) => {
            el.innerHTML = moneyWithoutTrailingZeros(total);
        });
    }

    updateSubtotal() {
        const elements = this.container?.querySelectorAll(selectors?.subtotal);
        if (!elements) {
            return;
        }

        if (!elements?.length) {
            return;
        }

        const subtotal = this.subtotal || 0.0;

        elements?.forEach((el) => {
            el.innerHTML = moneyWithoutTrailingZeros(subtotal);

            if (subtotal > this.total) {
                el.classList.remove('hide');
                return;
            }

            el.classList.add('hide');
        });
    }

    getVariantById(variant_id) {
        if (!variant_id) {
            return null;
        }

        const product = this.product;
        if (!product) {
            return null;
        }

        const variants = this.product?.variants;
        if (!variants) {
            return null;
        }

        if (!variants?.length) {
            return null;
        }

        return variants?.find((item) => {
            return +item?.id === +variant_id;
        }) || null;
    }

    getProductFeaturedImage() {
        const { product } = this;
        if (!product) {
            return null;
        }

        const featured_image = product?.featured_image;
        if (!featured_image) {
            return null;
        }

        return featured_image;
    }

    getImageBySelectedOptions(selectedOptions) {
        const { product } = this;
        if (!product) {
            return this.getProductFeaturedImage();
        }

        const images = product?.images;
        if (!images) {
            return this.getProductFeaturedImage();
        }

        if (!images?.length) {
            return this.getProductFeaturedImage();
        }

        const option_values = Object.keys(selectedOptions).map((key) => {
            return selectedOptions[key];
        });

        if (!option_values) {
            return this.getProductFeaturedImage();
        }

        if (!option_values?.length) {
            return this.getProductFeaturedImage();
        }

        const conditions = option_values?.map((value, index) => {
            return [...option_values].slice(0, index);
        })?.map((values) => {
            return values.join(' / ');
        })
        ?.filter((condition) => {
            return Boolean(condition);
        })?.reverse();

        if (!conditions) {
            return this.getProductFeaturedImage();
        }

        if (!conditions?.length) {
            return this.getProductFeaturedImage();
        }

        const filtered_images = conditions?.map((condition) => {
            return images?.find((image) => {
                return image?.alt?.toLowerCase()?.includes(condition?.toLowerCase());
            });
        })?.filter((image) => {
            return Boolean(image);
        });

        if (!filtered_images) {
            return this.getProductFeaturedImage();
        }

        if (!filtered_images?.length) {
            return this.getProductFeaturedImage();
        }

        const image = filtered_images[0];
        if (!image) {
            return this.getProductFeaturedImage();
        }

        return image;
    }

    updateImage(selectedOptions) {
        const image = this.getImageBySelectedOptions(selectedOptions);
        if (!image) {
            return;
        }

        const src = image?.src;
        if (!src) {
            return;
        }

        const { container } = this;
        if (!container) {
            return;
        }

        const imageElements = container.querySelectorAll(`[data-quickview-image]`);
        if (!imageElements) {
            return;
        }

        if (!imageElements?.length) {
            return;
        }

        imageElements?.forEach((imageElement) => {
            imageElement.src = src;
        });
    }

    updateTitle(selectedOptions) {
        const { container } = this;
        if (!container) {
            return;
        }

        const titleOptions = Array.prototype.slice.apply(container?.querySelectorAll(selectors?.titleOption) || []);
        if (!titleOptions) {
            return;
        }

        if (!titleOptions?.length) {
            return;
        }

        titleOptions?.forEach((titleOption) => {
            const option = titleOption?.dataset?.option;
            if (!option) {
                return;
            }

            const value = selectedOptions[option];
            if (!value) {
                return;
            }

            titleOption.innerHTML = value;
        });
    }

    open(event) {
        const { quickViewModal } = this;
        if (!quickViewModal) {
            return;
        }

        initModals();

        disableBodyScroll(quickViewModal, {
            reserveScrollBarGap: false,
            allowTouchMove(el) {
                while (el && el !== document?.body) {
                    if (el.getAttribute('data-allow-scroll') !== null) {
                        return true;
                    }

                    el = el?.parentElement;
                }

                return false;
            }
        });

        quickViewModal?.classList?.add(selectors?.opened);

        const detail = event?.detail;
        if (!detail) {
            return;
        }

        const target = detail?.target;
        if (!target) {
            return;
        }

        this.button = target;
        this.initCurrentVariant();
    }

    close() {
        const { quickViewModal } = this;
        if (!quickViewModal) {
            return;
        }

        quickViewModal?.classList?.remove(selectors?.opened);

        enableBodyScroll(quickViewModal);

        this.selectedVariants = [];

        const container = this.container;
        if (!container) {
            return;
        }

        container?.remove();
    }

    onCartChange(event) {
        const container = this.container;
        if (!container) {
            return;
        }

        const { detail } = event;
        if (!detail) {
            return;
        }

        const { cart } = detail;
        if (!cart) {
            return;
        }

        this.cart = cart;

        this.updateSubmitButton();
        this.updatePrices();
    }
}

sections.register(SECTION_NAME, {
    async onLoad() {
        const { container } = this;
        if (!container) {
            return;
        }

        new QuickView(container);
    },
});

document.addEventListener('DOMContentLoaded', () => {
    sections.load([SECTION_NAME]);
});

function initQuickViewModals() {
    document.addEventListener('click', async (event) => {
        let target = event?.target;
        if (target === document) {
            return;
        }

        if (!target?.hasAttribute('data-quickview-button')) {
            target = target?.closest('[data-quickview-button]');
        }

        if (!target) {
            return;
        }

        const handle = target?.dataset?.productHandle;
        if (!handle) {
            return;
        }

        const variant_id = target?.dataset?.variantId || null;

        const quickviewElement = document.querySelector(`[data-quickview][data-product-handle="${handle}"]${variant_id ? `[data-variant-id="${handle}"]` : ''}`);
        if (!!quickviewElement) {
            quickviewElement.dispatchEvent(new CustomEvent("quickview:open", {
                detail: {
                    target,
                }
            }));

            return;
        }

        event.preventDefault();

        try {
            target.disabled = true;
            target?.classList?.add('loading');

            const response = await axios?.get(`/products/${handle}`, {
                params: {
                    view: 'quickview',
                    ...(!!variant_id ? {
                        variant: variant_id,
                    } : {}),
                }
            })?.then((response) => {
                return response?.data;
            });

            if (!response) {
                return;
            }

            const quickviewElement = document.createElement('div');
            quickviewElement.innerHTML = response?.toString();
            document.body.append(quickviewElement);

            sections.load([SECTION_NAME]);

            quickviewElement?.querySelector(`[data-section-type="${SECTION_NAME}"]`)?.dispatchEvent(new CustomEvent("quickview:open", {
                detail: {
                    target,
                }
            }));
        }
        catch (error) {}
        finally {
            target.disabled = false;

            target?.classList?.remove('loading');
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initModals();

    initQuickViewModals();
});
